var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"编辑"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"套餐名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入套餐名称' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入套餐名称' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"运营商套餐ID"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['carrier_package_id', {
            normalize: this.$lodash.trim,
            rules: [
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['carrier_package_id', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}]})],1),_c('a-form-item',{staticClass:"simiot-display-item",attrs:{"label":"通用套餐名称"}},[_vm._v(" "+_vm._s(_vm.data.base_carrier_product_name)+" ")]),_c('a-form-item',{staticClass:"simiot-display-item",attrs:{"label":"标签"}},[_vm._v(" "+_vm._s(_vm.data.tags_display)+" ")]),_c('a-form-item',{staticClass:"simiot-display-item",attrs:{"label":"共享属性"}},[_vm._v(" "+_vm._s(_vm.data.traffic_share_property_display)+" ")]),_c('a-form-item',{staticClass:"simiot-display-item",attrs:{"label":"服务周期"}},[_vm._v(" "+_vm._s(_vm.data.service_cycle_display)+" ")]),(_vm.data.traffic_share_property !== 'backward')?_c('a-form-item',{staticClass:"simiot-display-item",attrs:{"label":"套餐容量"}},[_vm._v(" "+_vm._s(_vm.data.package_capacity_display)+" ")]):_vm._e(),_c('a-form-item',{staticClass:"simiot-display-item",attrs:{"label":"语音"}},[_vm._v(" "+_vm._s(_vm.data.voice_capacity_display)+" ")]),_c('a-form-item',{attrs:{"label":"流量属性"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['traffic_property',{
            rules: [
              { required: true, message: '请选择流量属性' } ]
          }]),expression:"['traffic_property',{\n            rules: [\n              { required: true, message: '请选择流量属性' },\n            ]\n          }]"}],attrs:{"disabled":!_vm.isTrafficPropertyCanEdit}},_vm._l((_vm.trafficPropertyOptions),function(option){return _c('a-radio',{key:option.value,attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"套餐价格"}},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['price', {
              rules: [
                { required: true, message: '请输入套餐价格' } ]
            }]),expression:"['price', {\n              rules: [\n                { required: true, message: '请输入套餐价格' },\n              ]\n            }]"}],staticStyle:{"width":"256px"},attrs:{"min":0,"max":10000000,"step":0.01,"precision":2}}),_c('span',[_vm._v(_vm._s(_vm.priceLabelUint))])],1)],1),_c('a-form-item',{attrs:{"label":"超额资费","required":""}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-form-item',{style:({ display: 'inline-block', width: '50%', marginBottom: 0 })},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['excess_price', {
                  rules: [
                    { required: true, message: '请输入超额资费' } ]
                }]),expression:"['excess_price', {\n                  rules: [\n                    { required: true, message: '请输入超额资费' },\n                  ]\n                }]"}],staticStyle:{"width":"110px"},attrs:{"min":0,"max":10000000,"step":0.01,"precision":2}}),_c('span',[_vm._v("元/")])],1)],1),_c('a-form-item',{style:({ display: 'inline-block', width: '50%', marginBottom: 0 })},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['excess_price_quantity', {
                  rules: [
                    { required: true, message: '请输入超额资费' } ]
                }]),expression:"['excess_price_quantity', {\n                  rules: [\n                    { required: true, message: '请输入超额资费' },\n                  ]\n                }]"}],staticStyle:{"width":"110px"},attrs:{"min":1,"max":10000000,"step":1,"precision":0}}),_c('span',[_vm._v(_vm._s(_vm.excessPriceQuantityLabelUnit))])],1)],1)],1)],1),_c('a-form-item',{attrs:{"label":"套餐描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'description',
            {
              rules: [{ max: 200, message: '最多200个字符' }],
            } ]),expression:"[\n            'description',\n            {\n              rules: [{ max: 200, message: '最多200个字符' }],\n            },\n          ]"}],attrs:{"rows":3}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }