<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >

        <a-form-item label="套餐名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入套餐名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="运营商套餐ID">
          <a-input
            v-decorator="['carrier_package_id', {
              normalize: this.$lodash.trim,
              rules: [
                { max: 100, message: '最多100个字符' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item
          label="通用套餐名称"
          class="simiot-display-item"
        >
          {{ data.base_carrier_product_name }}
        </a-form-item>

        <a-form-item
          label="标签"
          class="simiot-display-item"
        >
          {{ data.tags_display }}
        </a-form-item>

        <a-form-item
          label="共享属性"
          class="simiot-display-item"
        >
          {{ data.traffic_share_property_display }}
        </a-form-item>

        <a-form-item
          label="服务周期"
          class="simiot-display-item"
        >
          {{ data.service_cycle_display }}
        </a-form-item>

        <a-form-item
          label="套餐容量"
          class="simiot-display-item"
          v-if="data.traffic_share_property !== 'backward'"
        >
          {{ data.package_capacity_display }}
        </a-form-item>

        <a-form-item
          label="语音"
          class="simiot-display-item"
        >
          {{ data.voice_capacity_display }}
        </a-form-item>

        <a-form-item label="流量属性">
          <a-radio-group
            :disabled="!isTrafficPropertyCanEdit"
            v-decorator="['traffic_property',{
              rules: [
                { required: true, message: '请选择流量属性' },
              ]
            }]"
          >
            <a-radio v-for="option in trafficPropertyOptions" :value="option.value" :key="option.value">
              {{ option.label }}
            </a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="套餐价格">
          <a-space>
            <a-input-number
              style="width: 256px;"
              :min="0"
              :max="10000000"
              :step="0.01"
              :precision="2"
              v-decorator="['price', {
                rules: [
                  { required: true, message: '请输入套餐价格' },
                ]
              }]"
            />
            <span>{{ priceLabelUint }}</span>
          </a-space>
        </a-form-item>

        <a-form-item label="超额资费" required>
          <a-input-group compact>
            <a-form-item :style="{ display: 'inline-block', width: '50%', marginBottom: 0 }">
              <a-space>
                <a-input-number
                  style="width: 110px"
                  :min="0"
                  :max="10000000"
                  :step="0.01"
                  :precision="2"
                  v-decorator="['excess_price', {
                    rules: [
                      { required: true, message: '请输入超额资费' },
                    ]
                  }]"
                />
                <span>元/</span>
              </a-space>
            </a-form-item>

            <a-form-item :style="{ display: 'inline-block', width: '50%', marginBottom: 0 }">
              <a-space>
                <a-input-number
                  style="width: 110px"
                  :min="1"
                  :max="10000000"
                  :step="1"
                  :precision="0"
                  v-decorator="['excess_price_quantity', {
                    rules: [
                      { required: true, message: '请输入超额资费' },
                    ]
                  }]"
                />
                <span>{{ excessPriceQuantityLabelUnit }}</span>
              </a-space>
            </a-form-item>
          </a-input-group>
        </a-form-item>

        <a-form-item label="套餐描述">
          <a-textarea
            :rows="3"
            v-decorator="[
              'description',
              {
                rules: [{ max: 200, message: '最多200个字符' }],
              },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findCarrierProductForm, updateCarrierProduct } from '@/api/carrier_product'
import { findTrafficPropertyOptions } from '@/api/common_options'

export default {
  name: 'EditCarrierProduct',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    carrierAccountId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'carrier_product' }),
      submitting: false,
      data: {},
      isTrafficPropertyCanEdit: false,
      capacityUnit: '',
      trafficShareProperty: '',
      trafficPropertyOptions: findTrafficPropertyOptions()
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    // 套餐价格 输入框后的单位
    priceLabelUint() {
      return this.trafficShareProperty === 'backward' ? '元/GB' : '元'
    },

    // 超额资费 输入框后的单位
    excessPriceQuantityLabelUnit() {
      return this.capacityUnit === 'count' ? '次' : 'MB'
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      findCarrierProductForm(this.id).then((res) => {
        this.data = res.data
        this.capacityUnit = res.data.capacity_unit
        this.trafficShareProperty = res.data.traffic_share_property
        // 流量属性只能编辑一次，保存后不能再更改
        this.isTrafficPropertyCanEdit = res.data.traffic_property === 'pending'
        // 处理数值
        const trafficProperty = res.data.traffic_property === 'pending' ? undefined : res.data.traffic_property
        const price = res.data.price === -1 ? undefined : res.data.price
        const excessPrice = res.data.excess_price === -1 ? undefined : res.data.excess_price
        const excessPriceQuantity = res.data.excess_price_quantity === -1 ? undefined : res.data.excess_price_quantity
        this.form.setFieldsValue({
          name: res.data.name,
          carrier_package_id: res.data.carrier_package_id,
          price: price,
          excess_price: excessPrice,
          excess_price_quantity: excessPriceQuantity,
          traffic_property: trafficProperty,
          description: res.data.description
        })
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = Object.assign({ carrier_account_id: this.carrierAccountId }, values)
          updateCarrierProduct(this.id, data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
